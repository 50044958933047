import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Header from "./Header";
import Footer from "./FooterSection";

const AboutFaithfulsContainer = styled.div`
  overflow-x: hidden;
  min-height: 100vh;
`;

// Motion variants for text and image animations
const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 1, when: "beforeChildren", staggerChildren: 0.3 },
  },
};

const textVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const imageVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
};

const AboutFaithfuls = () => {
  return (
    <AboutFaithfulsContainer>
      {/* Header Navigation */}
      <Header />

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        variants={containerVariants}
      >
        <StoryContainer>
          <TextContainer>
            <motion.h1 variants={textVariants}>
              The Faithfuls App: A Story of Connection and Community
            </motion.h1>
            <motion.p variants={textVariants}>
              The Faithfuls app was born out of the desire to bridge the gap
              between church communities and their members in a fast-paced,
              increasingly digital world. Its creators envisioned a world where
              staying connected to one’s church, no matter where you are, could
              be as seamless as checking your messages or scrolling through
              social media. In essence, The Faithfuls is designed to bring the
              church into the digital age, fostering spiritual growth,
              engagement, and a sense of belonging among the faithful.
            </motion.p>
            <motion.h2 variants={textVariants}>The Concept</motion.h2>
            <motion.p variants={textVariants}>
              The app was developed with the understanding that church isn't
              just about Sunday services; it’s about community, support, and
              spiritual growth that extends beyond the walls of a physical
              building. Many people may struggle to stay connected due to
              distance, time constraints, or other personal reasons. The
              Faithfuls aims to solve this by providing a platform where users
              can connect with their church from anywhere in the world.
            </motion.p>

            <motion.h2 variants={textVariants}>Key Features</motion.h2>
            <motion.ul variants={textVariants}>
              <li>
                <strong>Connect to Your Church:</strong> The app allows users to
                join their church communities, follow up on activities, events,
                prayers, and programs.
              </li>
              <li>
                <strong>Global Reach:</strong> For those traveling or
                relocating, distance is no longer a barrier. The app ensures
                users can participate in services remotely.
              </li>
              <li>
                <strong>Multi-church Membership:</strong> Users can stay
                connected with multiple churches, no matter where they are
                located.
              </li>
              <li>
                <strong>Church Management Tools:</strong> Church leaders can
                send announcements, schedule events, track attendance, and
                manage donations.
              </li>
            </motion.ul>

            <motion.h2 variants={textVariants}>Why It Matters</motion.h2>
            <motion.p variants={textVariants}>
              In a world where people turn to technology for communication and
              connection, The Faithfuls app modernizes religious engagement by
              making church experiences more accessible and inclusive.
            </motion.p>

            <motion.h2 variants={textVariants}>Vision for the Future</motion.h2>
            <motion.p variants={textVariants}>
              The Faithfuls aims to continue enhancing the app with more
              interactive features like real-time service streaming, community
              forums, and personalized spiritual growth plans.
            </motion.p>
          </TextContainer>

          <ImageContainer>
            <motion.img
              src="/assets/phone.png"
              alt="Faithfuls App on Phone"
              variants={imageVariants}
            />
            <motion.img
              src="https://th.bing.com/th/id/OIP.4OGe5t_r-_GUPw8LXf9eVAHaEK?rs=1&pid=ImgDetMain"
              alt="Church Community"
              width={"100%"}
              variants={imageVariants}
            />
          </ImageContainer>
        </StoryContainer>
      </motion.div>

      {/* Footer Section */}
      <Footer />
    </AboutFaithfulsContainer>
  );
};

export default AboutFaithfuls;

const StoryContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 50px;
  padding: 100px;
  background-color: #f5f5f5;
  color: #333;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 50px;
  }
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    padding: 100px 20px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  max-width: 60%;

  h1,
  h2 {
    color: #380b96;
  }

  p {
    line-height: 1.8;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  flex: 1;

  @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    img {
      max-width: 70%;
    }

    img:last-child {
      display: none;
    }
  }
`;
