import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const About = () => {
  // Variants for animation
  const fadeInUp = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <AboutSection
      as={motion.section}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 1.2 }}
      id="about"
    >
      <Content>
        <motion.h2 variants={fadeInUp} transition={{ duration: 1, delay: 0.2 }}>
          About Our Church
        </motion.h2>
        <motion.p variants={fadeInUp} transition={{ duration: 1, delay: 0.4 }}>
          We aim to connect believers to the church wherever they are. Our
          platform provides a seamless way for church members to stay in touch
          with church activities, sermons, and the community.
        </motion.p>
        <motion.p variants={fadeInUp} transition={{ duration: 1, delay: 0.6 }}>
          Whether you are at home or traveling, you can be part of the church's
          journey. Stay connected with us.
        </motion.p>
        <motion.div
          variants={fadeInUp}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <ActionButton href="/about">Learn More</ActionButton>
        </motion.div>
      </Content>

      {/* Curve under the section */}
      <CurveContainer>
        <svg viewBox="0 0 1440 320">
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,224L80,202.7C160,181,320,139,480,128C640,117,800,139,960,154.7C1120,171,1280,181,1360,186.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </CurveContainer>
    </AboutSection>
  );
};

export default About;

const AboutSection = styled.section`
  background-color: #ffffff;
  padding: 0px 100px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: #333;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 50px 20px;
  }
`;

const Content = styled.div`
  max-width: 600px;

  h2 {
    font-size: 36px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 28px;
    }

    @media (max-width: 480px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const ActionButton = styled.a`
  background-color: #b603c9;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.9;
    background-color: ${(props) => (props.primary ? "#9400a1" : "#6e2cb4")};
    border-color: ${(props) => (props.primary ? "none" : "#b603c9")};
    color: #fff;
  }

  @media (max-width: 480px) {
    padding: 12px 20px;
    font-size: 14px;
  }
`;

const CurveContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;
