import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Hero = () => {
  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const staggerContainer = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // Delay between child animations
      },
    },
  };

  return (
    <HeroSection id="home">
      <Content
        as={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
      >
        <motion.div variants={fadeInUp}>
          <h1>Connect With Your Church</h1>
          <p>
            Connect with your church from anywhere and follow up with all your
            church activities and prayers using The Faithfuls App.
          </p>
        </motion.div>
        <ButtonGroup as={motion.div} variants={fadeInUp}>
          <DownloadButton
            primary
            href={`${process.env.REACT_APP_REGISTER_URL}`}
          >
            Join Your Church
          </DownloadButton>
          <DownloadButton href={`${process.env.REACT_APP_REGISTER_URL}`}>
            Add Your Church
          </DownloadButton>
        </ButtonGroup>
        <motion.div variants={fadeInUp}>
          <h2>Get The Faithfuls App</h2>
          <p>
            Get the Faithfuls App to remain connected to all your churches from
            anywhere in the world.
          </p>
        </motion.div>
        <StoreIcons as={motion.div} variants={fadeInUp}>
          <a href={`${process.env.REACT_APP_APPLE_STORE_URL}`}>
            <img src="/assets/app-store.png" alt="App Store" />
          </a>
          <a href={`${process.env.REACT_APP_GOOGLE_STORE_URL}`}>
            <img src="/assets/google-play.png" alt="Google Play" />
          </a>
        </StoreIcons>
      </Content>
      <PhoneImage
        src="/assets/phone.png"
        alt="Phone"
        animate={{
          y: [0, -10, 0], // Moves from initial position to -10px and back to 0px
        }}
        transition={{
          duration: 1, // Full bounce cycle duration
          repeat: Infinity, // Infinite loop
          repeatType: "reverse", // Reverses back after reaching -10px
          ease: "easeInOut",
        }}
      />
      ;{/* SVG Curve */}
      <CurveContainer>
        <svg viewBox="0 0 1440 200">
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,120L80,120C160,120,320,120,480,128C640,136,800,152,960,136C1120,120,1280,72,1360,48L1440,24L1440,200L1360,200C1280,200,1120,200,960,200C800,200,640,200,480,200C320,200,160,200,80,200L0,200Z"
          ></path>
        </svg>
      </CurveContainer>
    </HeroSection>
  );
};

export default Hero;

const HeroSection = styled.section`
  background: url("/assets/hero-bg.svg");
  padding: 100px 100px 10px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: relative;
  overflow: hidden;
  padding-bottom: 200px; /* Ensure enough space for the curve */

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 100px 20px;
     /* Adjust for smaller screens */
  }
`;

const Content = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    font-size: 48px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 36px;
    }

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const DownloadButton = styled.a`
  background-color: ${(props) => (props.primary ? "#b603c9" : "transparent")};
  color: white;
  padding: 15px 30px;
  border: ${(props) => (props.primary ? "none" : "2px solid white")};
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.9;
    background-color: ${(props) => (props.primary ? "#9400a1" : "#6e2cb4")};
    border-color: ${(props) => (props.primary ? "none" : "#b603c9")};
    color: #fff;
  }

  @media (max-width: 480px) {
    padding: 12px 20px;
    font-size: 14px;
  }
`;

const StoreIcons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;

  img {
    width: 150px;

    @media (max-width: 480px) {
      width: 120px;
    }
  }
`;

const PhoneImage = styled(motion.img)`
  width: 300px;
  height: 400px;

  @media (max-width: 1024px) {
    margin-top: 30px;
    width: 300px;
  }

  @media (max-width: 768px) {
    width: 250px;
  }

  @media (max-width: 480px) {
    width: 200px;
    max-width: 100%;
    max-height: 60vh;
    object-fit: contain;
  }
`;

const CurveContainer = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;
