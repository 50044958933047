// Feedback.js
import React from "react";
import styled from "styled-components";

const Feedback = () => {
  return (
    <FeedbackSection>
      <h2>Send Us Feedback</h2>
      <Form>
        <input type="text" placeholder="Your Email" />
        <input type="text" placeholder="Your Message" />
        <SubmitButton>Submit</SubmitButton>
      </Form>
    </FeedbackSection>
  );
};

export default Feedback;

const FeedbackSection = styled.section`
  background-color: #fff;
  padding: 50px 20px;
  text-align: center;

  h2 {
    font-size: 36px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 28px;
    }

    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;

    @media (max-width: 480px) {
      padding: 12px;
      font-size: 14px;
    }
  }
`;

const SubmitButton = styled.button`
  background-color: #b603c9;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 430px;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.9;
    background-color: ${(props) => (props.primary ? "#9400a1" : "#6e2cb4")};
    border-color: ${(props) => (props.primary ? "none" : "#b603c9")};
  }

  @media (max-width: 480px) {
    padding: 12px 20px;
    font-size: 14px;
  }
`;
