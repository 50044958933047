import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <Logo>
        <img src="/assets/Logo.svg" alt="" />
      </Logo>

      {/* Hamburger for mobile */}
      <Hamburger onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </Hamburger>

      {/* Navbar Links */}
      <NavLinks className={isOpen ? "open" : ""}>
        <a href="/">Home</a>
        <a href="/about">About</a>
        {pathname === "/" && <a href="#features">Features</a>}
        {pathname === "/" && <a href="#contact">Contact</a>}
        {/* Buttons */}
        <Button primary href={`${process.env.REACT_APP_LOGIN_URL}`}>
          Login
        </Button>
        <Button href={`${process.env.REACT_APP_REGISTER_URL}`}>Register</Button>
      </NavLinks>

      {/* Overlay for mobile */}
      {isOpen && <Overlay onClick={toggleMenu} />}
    </HeaderContainer>
  );
};

export default Header;

// Styled Components
const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  padding: 0 100px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const Logo = styled.div`
  img {
    width: 150px;
    height: auto;
  }

  @media (max-width: 1024px) {
    img {
      width: 120px;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 100px;
    }
  }

  @media (max-width: 480px) {
    img {
      width: 80px;
    }
  }
`;

const NavLinks = styled.nav`
  display: flex;
  align-items: center;

  a {
    color: white;
    margin-left: 30px;
    font-size: 18px;
    text-decoration: none;
    transition: all 0.5s ease;

    &:hover {
      color: #b603c9;
    }
  }

  /* Buttons inside the Nav */
  button {
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: #b603c9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 2; /* Stay on top */

    &.open {
      transform: translateX(0);
    }

    a {
      margin: 20px 0;
      font-size: 22px;
    }

    button {
      margin: 10px 0;
      width: 80%;
    }
  }
`;

const Button = styled.a`
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  background-color: ${(props) => (props.primary ? "#b603c9" : "transparent")};
  color: #fff;
  border: ${(props) => (props.primary ? "none" : "1px solid white")};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.primary ? "#9400a1" : "#6e2cb4")};
    border-color: ${(props) => (props.primary ? "none" : "#b603c9")};
    color: #fff;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 3px;
    width: 25px;
    background-color: white;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;
