// App.js
import React from "react";
import styled from "styled-components";
import LandingPage from "./LandingPage";
import PrivacyPolicy from "./Sections/PrivacyPolicy";
import TermsAndCondition from "./Sections/TermsAndCondition";
import AboutFaithfuls from "./Sections/AboutFaithfuls";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const AppContainer = styled.div`
  overflow-x: hidden;
`;

const App = () => {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/about" element={<AboutFaithfuls />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

export default App;
