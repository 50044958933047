// Features.js
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Features = () => {
  // Custom animations for each feature
  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <FeaturesSection>
      <Feature
        as={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.5 }}
              variants={fadeInLeft} // Slide in from left
      >
        <FeatureLeftContent>
          <FeatureTitle>
            Connect with Your Local Church From Anywhere
          </FeatureTitle>
          <FeatureDescription>
            Connect with your local church from anywhere in the world and never
            miss any of your church programmes with the Faithful App
          </FeatureDescription>
        </FeatureLeftContent>
        <FeatureRightContent>
          <img
            src="https://thumbs.dreamstime.com/b/technology-concept-closeup-portrait-afro-person-using-cell-phone-sitting-outdoors-selective-focus-device-portrait-black-192424661.jpg"
            alt="Feature"
          />
        </FeatureRightContent>
      </Feature>

      <Feature
        as={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.5, delay: 0.5 }}
        variants={fadeInRight} // Slide in from right
      >
        <FeatureLeftContent>
          <img
            src="https://th.bing.com/th/id/OIP.MbiBtAnFyXDniCIKIvIp5QHaE8?w=2121&h=1414&rs=1&pid=ImgDetMain"
            alt="Feature"
          />
        </FeatureLeftContent>
        <FeatureRightContent>
          <FeatureTitle>
            Stay Updated And Informed on All Your Church Events
          </FeatureTitle>
          <FeatureDescription>
            Get update on all your church’s upcoming events so you don’t
            miss-out on them. Stay updated on upcoming events with time and
            date, follow-up with ongoing events.
          </FeatureDescription>
        </FeatureRightContent>
      </Feature>

      <Feature
        as={motion.div}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.5, delay: 1 }} // Slow down the animation and add delay
        variants={fadeInUp} // Slide in from bottom
      >
        <FeatureLeftContent>
          <FeatureTitle>Daily Reflections and Bible Studies</FeatureTitle>
          <FeatureDescription>
            Connect with your local church from anywhere in the world and never
            miss any of your church programmes with the Faithful App
          </FeatureDescription>
        </FeatureLeftContent>
        <FeatureRightContent>
          <img
            src="https://wallpapercrafter.com/desktop3/1005979-bible-scripture-holding-hold-gods-word-god-reading.jpg"
            alt="Feature"
          />
        </FeatureRightContent>
      </Feature>
    </FeaturesSection>
  );
};

export default Features;

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 100px;
  background-color: #f9f9f9;
  padding: 50px 20px;

  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const Feature = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FeatureLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  img {
    width: 70%;
    height: auto;
    border-radius: 20px;
  }
`;

const FeatureRightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  img {
    width: 70%;
    height: auto;
    border-radius: 20px;
  }
`;

const FeatureTitle = styled.div`
  font-weight: 600;
  font-size: 30px;
  width: 50%;
  /* text-align: center */

  @media (max-width: 1024px) {
    font-size: 26px;
    width: 70%;
  }

  @media (max-width: 768px) {
    font-size: 22px;
    width: 80%;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    width: 90%;
  }
`;

const FeatureDescription = styled.p`
  font-size: 18px;
  width: 50%;
  /* text-align: center; */
  color: #666;

  @media (max-width: 1024px) {
    font-size: 16px;
    width: 70%;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    width: 80%;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    width: 90%;
  }
`;
