import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <FooterSection>
      <FooterContent>
        <Logo>
          <img src="/assets/Logo.svg" alt="" />
        </Logo>
        <Links>
          <LinkItem href="#home">Home</LinkItem>
          <LinkItem href="/about">About Us</LinkItem>
          {pathname === "/" && <LinkItem href="#features">Features</LinkItem>}
          {pathname === "/" && <LinkItem href="#contact">Contact</LinkItem>}
          <LinkItem href="/terms-and-conditions">Terms & conditions</LinkItem>
          <LinkItem href="/privacy-policy">Privacy Policy</LinkItem>
        </Links>
        <ContactInfo>
          <a href="mailto:info@faithfuls.com">Email: info@faithfuls.com</a>
          {/* <p>Phone: +123 456 7890</p> */}
        </ContactInfo>
        <SocialIcons>
          <a href="facebook.com">
            <img src="/assets/facebook.svg" alt="Facebook" />
          </a>
          <a href="twitter.com">
            <img src="/assets/twitter.svg" alt="Twitter" />
          </a>
          <a href="instagram.com">
            <img src="/assets/instagram.svg" alt="Instagram" />
          </a>
        </SocialIcons>
      </FooterContent>
      <FooterBottom>
        <p>&copy; 2024 Faithfuls. All rights reserved.</p>
      </FooterBottom>
    </FooterSection>
  );
};

export default Footer;

const FooterSection = styled.footer`
  background-color: #383838; /* Match the background color */
  padding: 40px 20px;
  color: white;
  position: relative;
  bottom: 0;
  left: 0;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Logo = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const Links = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const LinkItem = styled.a`
  color: white;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ContactInfo = styled.div`
  text-align: center;

  p {
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;

  img {
    width: 24px; /* Adjust the size of the social icons */
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 10px;
`;
