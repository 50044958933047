// LandingPage.js
import React from "react";
import Header from "./Sections/Header";
import Hero from "./Sections/HeroSection";
import About from "./Sections/About";
import Features from "./Sections/Features";
import CallToAction from "./Sections/CallToActionSection";
import Feedback from "./Sections/FeedbackSection";
import Footer from "./Sections/FooterSection";
import ExploreFeatures from "./Sections/ExploreFeatures";
import styled from "styled-components";

const LandingPageContainer = styled.div`
  overflow-x: hidden;
`;

const LandingPage = () => {
  return (
    <LandingPageContainer>
      {/* Header Navigation */}
      <Header />

      {/* Hero Section */}
      <Hero />

      {/* About Section */}
      <About />

      {/* explore features */}
      <ExploreFeatures />

      {/* Features Section */}
      <Features />

      {/* Call to Action Section */}
      <CallToAction />

      {/* Feedback Section */}
      <Feedback />

      {/* Footer Section */}
      <Footer />
    </LandingPageContainer>
  );
};

export default LandingPage;
