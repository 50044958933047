// TermsAndConditions.js
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./FooterSection";

const TermsAndConditionsContainer = styled.div`
  overflow-x: hidden;
  min-height: 100vh;
`;

const TermsAndConditions = () => {
  return (
    <TermsAndConditionsContainer>
      {/* Header Navigation */}
      <Header />

      <TermsContainer>
        <Heading>Terms and Conditions</Heading>

        <Section>
          <SubHeading>1. Introduction</SubHeading>
          <p>
            Welcome to The Faithfuls App. By accessing and using our services,
            you agree to comply with the following terms and conditions. If you
            disagree with any part of these terms, please do not use our app.
          </p>
        </Section>

        <Section>
          <SubHeading>2. Use of the Service</SubHeading>
          <p>
            The Faithfuls App grants you a non-exclusive, non-transferable,
            revocable license to use our services. You agree to use the app only
            for lawful purposes and not to engage in any activity that
            interferes with the operation of the app.
          </p>
        </Section>

        <Section>
          <SubHeading>3. User Accounts</SubHeading>
          <p>
            To access certain features, you may be required to create an
            account. You are responsible for maintaining the confidentiality of
            your account information and agree to notify us immediately of any
            unauthorized use.
          </p>
        </Section>

        <Section>
          <SubHeading>4. Intellectual Property</SubHeading>
          <p>
            All content provided through the app, including text, images, logos,
            and software, is the property of The Faithfuls or its licensors and
            is protected by applicable intellectual property laws.
          </p>
        </Section>

        <Section>
          <SubHeading>5. Termination</SubHeading>
          <p>
            We reserve the right to terminate or suspend your access to the app
            at our discretion, without notice, if you violate these terms or
            engage in any harmful activity.
          </p>
        </Section>

        <Section>
          <SubHeading>6. Limitation of Liability</SubHeading>
          <p>
            The Faithfuls shall not be held liable for any damages arising from
            the use or inability to use the app, including but not limited to
            indirect, incidental, or consequential damages.
          </p>
        </Section>

        <Section>
          <SubHeading>7. Changes to Terms</SubHeading>
          <p>
            We may update these terms from time to time. It is your
            responsibility to review the terms periodically. Your continued use
            of the app following any updates constitutes your acceptance of the
            revised terms.
          </p>
        </Section>

        <Section>
          <SubHeading>8. Governing Law</SubHeading>
          <p>
            These terms are governed by and construed in accordance with the
            laws of [Your Country/Region], without regard to its conflict of law
            principles.
          </p>
        </Section>

        <Section>
          <SubHeading>9. Contact Us</SubHeading>
          <p>
            If you have any questions about these terms, please contact us at
            support@thefaithfuls.com.
          </p>
        </Section>
      </TermsContainer>

      {/* Footer Section */}
      <Footer />
    </TermsAndConditionsContainer>
  );
};

export default TermsAndConditions;

const TermsContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #333;

  @media (max-width: 768px) {
    padding: 100px 15px;
    margin: 20px;
  }
`;

const Heading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: #380b96;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;

  p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
`;

const SubHeading = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #590b96;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;