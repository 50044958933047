// PrivacyPolicy.js
import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./FooterSection";

const PrivacyPolicyContainer = styled.div`
  overflow-x: hidden;
  min-height: 100%;
`;

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      {/* Header Navigation */}
      <Header />

      <PolicyContainer>
        <Heading>Thefaithfuls.com Privacy Policy</Heading>

        <Section>
          <SubHeading>Introduction</SubHeading>
          <p>
            The Faithfuls (the “App”) protects and respects your privacy. This
            Privacy Policy explains the types of information we collect from
            you, how we use, disclose, and safeguard that information, and your
            rights with respect to it. By using the App, you agree to the
            collection and use of information in accordance with this policy.
            Please read it carefully to understand our practices regarding your
            personal data and how we will treat it.
          </p>
        </Section>

        <Section>
          <SubHeading>Information We Collect</SubHeading>

          <SubSubHeading>1. Church Administrators</SubSubHeading>
          <p>
            When Church Administrators sign up and use the App, we collect the
            following personal data:
          </p>
          <ul>
            <li>Name</li>
            <li>Email</li>
            <li>Phone number</li>
            <li>Date of Birth (DOB)</li>
            <li>Church name</li>
            <li>Church address</li>
            <li>Denomination</li>
            <li>Login credentials (username and password)</li>
          </ul>

          <SubSubHeading>2. Church Members</SubSubHeading>
          <p>
            When Church Members sign up and use the App, we collect the
            following personal data:
          </p>
          <ul>
            <li>Name</li>
            <li>Phone number</li>
            <li>Email</li>
            <li>Address</li>
            <li>Date of Birth (DOB)</li>
          </ul>
        </Section>

        <Section>
          <SubHeading>How We Use Your Information</SubHeading>
          <p>
            We collect and process your personal information for the following
            purposes:
          </p>
          <ul>
            <li>To provide, operate, and maintain the App</li>
            <li>To improve, personalize, and expand our services</li>
            <li>
              To communicate with you, including sending updates and
              notifications
            </li>
            <li>
              To manage user accounts and facilitate interaction within church
              communities
            </li>
            <li>To comply with legal obligations and enforce our policies</li>
            <li>
              To monitor and analyze usage and trends to improve your experience
            </li>
          </ul>
        </Section>

        <Section>
          <SubHeading>Legal Basis for Processing (EEA Users)</SubHeading>
          <p>
            If you are located in the European Economic Area (EEA), we process
            your personal data under the following legal bases:
          </p>
          <ul>
            <li>Your consent</li>
            <li>The performance of a contract</li>
            <li>Compliance with legal obligations</li>
            <li>Legitimate interests</li>
          </ul>
        </Section>

        <Section>
          <SubHeading>Consent</SubHeading>
          <p>
            By using our App, you consent to the collection and use of your
            information as described in this Privacy Policy. You have the right
            to withdraw your consent at any time.
          </p>
        </Section>

        <Section>
          <SubHeading>Third-Party Service Providers</SubHeading>
          <p>
            We may employ third-party companies and individuals to facilitate
            our services, provide services on our behalf, or assist us in
            analyzing how the App is used. These third parties have access to
            your personal data only to perform these tasks and are obligated not
            to disclose or use it for any other purpose.
          </p>
        </Section>

        <Section>
          <SubHeading>International Data Transfers</SubHeading>
          <p>
            Your personal data may be transferred to servers located outside of
            your country. We will take all steps reasonably necessary to ensure
            your data is treated securely and in accordance with this Privacy
            Policy.
          </p>
        </Section>

        <Section>
          <SubHeading>Sharing Your Information</SubHeading>
          <p>
            We do not sell or rent your personal data to third parties. However,
            we may disclose your personal data under certain circumstances such
            as legal obligations or to protect the rights of The Faithfuls and
            its users.
          </p>
        </Section>

        <Section>
          <SubHeading>Data Security</SubHeading>
          <p>
            We take reasonable steps to protect your data from unauthorized
            access, but no method of transmission over the Internet is 100%
            secure. We cannot guarantee absolute security.
          </p>
        </Section>

        <Section>
          <SubHeading>Data Retention</SubHeading>
          <p>
            We retain your personal data as long as necessary to provide our
            services and fulfill the purposes outlined in this policy. We may
            retain information to comply with legal obligations.
          </p>
        </Section>

        <Section>
          <SubHeading>Your Rights</SubHeading>
          <p>You have the following rights regarding your personal data:</p>
          <ul>
            <li>The right to access your data</li>
            <li>The right to rectify incorrect information</li>
            <li>The right to request deletion</li>
            <li>The right to restrict processing</li>
            <li>The right to data portability</li>
          </ul>
          <p>
            To exercise any of these rights, please contact us at
            support@thefaithfuls.com.
          </p>
        </Section>

        <Section>
          <SubHeading>Children's Privacy</SubHeading>
          <p>
            The Faithfuls is not intended for children under 13. We do not
            knowingly collect data from children under 13.
          </p>
        </Section>

        <Section>
          <SubHeading>Changes to This Privacy Policy</SubHeading>
          <p>
            We may update this Privacy Policy from time to time. Changes will be
            posted on this page, and we will notify you of significant changes
            via the App.
          </p>
        </Section>

        <Section>
          <SubHeading>Contact Information</SubHeading>
          <p>If you have any questions, please contact us at:</p>
          <p>Email: support@thefaithfuls.com</p>
          <p>Phone: +2347031586466</p>
        </Section>
      </PolicyContainer>

      {/* Footer Section */}
      <Footer />
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;

const PolicyContainer = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #333;

  @media (max-width: 768px) {
    padding: 100px 15px;
    margin: 20px;
  }
`;

const Heading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: #380b96;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;

  p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
`;

const SubHeading = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #590b96;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SubSubHeading = styled.h3`
  font-size: 20px;
  margin-bottom: 5px;
  color: #6b0a9b;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
