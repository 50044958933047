// CallToAction.js
import React from "react";
import styled from "styled-components";

const CallToAction = () => {
  return (
    <CTASection id="contact">
      <h2>Register Your Church</h2>
      <RegisterButton href={`${process.env.REACT_APP_REGISTER_URL}`}>
        Add Church
      </RegisterButton>
    </CTASection>
  );
};

export default CallToAction;

const CTASection = styled.section`
  background: url("/assets/hero-bg.svg");
  color: white;
  padding: 50px 20px;
  text-align: center;
  border-radius: 50px;

  h2 {
    font-size: 36px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 28px;
    }

    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
`;

const RegisterButton = styled.a`
  background-color: #fff;
  color: #4f1b9e;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  width: 300px;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #b603c9;
    color: #fff;
  }

  @media (max-width: 480px) {
    padding: 12px 20px;
    font-size: 14px;
  }
`;
