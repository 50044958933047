import React from "react";
import styled from "styled-components";

const ExploreFeatures = () => {
  return (
    <ExploreSection id="features">
      {/* <CurveTop /> */}
      <Content>
        <h2>Explore Our Wonderful Features</h2>
      </Content>
      {/* <CurveBottom /> */}
    </ExploreSection>
  );
};

export default ExploreFeatures;

const ExploreSection = styled.section`
  background: url("/assets/hero-bg.svg"); /* Purple background */
  padding: 60px 20px;
  position: relative;
  color: white;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto; /* Centering the content */
  text-align: center;

  h2 {
    font-size: 36px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

